<template>
	<div class="sticky top-0 py-8 px-4 md:px-0 bg-black z-50 border-[#878B954D] border-b">
		<div class="mx-auto container flex items-center justify-between">
			<div class="flex gap-20">
				<NuxtLink to="/dashboard"
					><NuxtImg
						class="h-10 w-auto"
						src="/images/logo.svg"
						alt="RealityNode - The fastest and most reliable private RPC."
				/></NuxtLink>
			</div>
			<div v-if="user" class="flex gap-2">
				<div class="hidden md:flex items-center gap-2.5">
					<i class="fi fi-br-wallet"></i>
					<p class="w-[140px] text-wrap text-ellipsis overflow-hidden">{{ user.walletAddress }}</p>
				</div>
				<AdminButton variant="secondary" @click="onLogout"><i class="fi fi-rr-exit"></i>Logout</AdminButton>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
const { user, logout } = useUserStore()
const { $api } = useNuxtApp()
const router = useRouter()

const onLogout = async () => {
	try {
		await $api('/auth/logout', {
			method: 'POST',
		})

		logout()

		router.push('/')
	} catch (error) {
		console.error(error)
	}
}
</script>
